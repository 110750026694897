import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GroupProvider } from './components/GroupContext'; // Import GroupProvider

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="https://dev-u2q46t7s0n53loac.us.auth0.com"
      clientId="COlQGnnw6qV7PDQVIMpnuleVBeXLIvuf"
      redirectUri={window.location.origin}
      audience="https://dev-u2q46t7s0n53loac.us.auth0.com/api/v2/"
      scope="openid profile email"
      cacheLocation="localstorage"
    >
      <Router>
        <GroupProvider> {/* Wrap App with GroupProvider */}
          <App />
        </GroupProvider>
      </Router>
    </Auth0Provider>
  </React.StrictMode>
);

reportWebVitals();