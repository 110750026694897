import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Select from 'react-select'; // Import react-select for type-ahead
import '../css/ManageMembersPage.css';

const ManageMembersPage = () => {
    const { groupId } = useParams();
    const [members, setMembers] = useState([]);
    const [selectedMember, setSelectedMember] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userOptions, setUserOptions] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        const fetchMembers = async () => {
            try {
                const response = await axios.get(`/api/groups/${groupId}/members`);
                console.log('Members API Response:', response.data); // Log the response
                setMembers(response.data || []); // Ensure it's an array
            } catch (error) {
                console.error('Error fetching members:', error);
            }
        };
    
        fetchMembers();
    }, [groupId]);

    const handleMenuClick = (member) => {
        setSelectedMember(member);
        setIsModalOpen(true);
    };

    const handleRemove = async () => {
        if (selectedMember) {
            try {
                await axios.put(`/api/groups/${groupId}/removeMember`, { userId: selectedMember._id });
                setMembers(members.filter(member => member._id !== selectedMember._id));
                setIsModalOpen(false);
                alert('Member removed successfully!');
            } catch (error) {
                console.error('Error removing member:', error);
                alert('Failed to remove member. Please try again.');
            }
        }
    };

    const handlePromote = async () => {
        if (selectedMember) {
            try {
                await axios.put(`/api/groups/${groupId}/promoteMember`, { userId: selectedMember._id });
                setMembers(members.map(member => 
                    member._id === selectedMember._id ? { ...member, isAdmin: true } : member
                ));
                setIsModalOpen(false);
                alert('Member promoted successfully!');
            } catch (error) {
                console.error('Error promoting member:', error);
                alert('Failed to promote member. Please try again.');
            }
        }
    };

    const handleDemote = async () => {
        if (selectedMember) {
            try {
                await axios.put(`/api/groups/${groupId}/demoteMember`, { userId: selectedMember._id });
                setMembers(members.map(member => 
                    member._id === selectedMember._id ? { ...member, isAdmin: false } : member
                ));
                setIsModalOpen(false);
                alert('Member demoted successfully!');
            } catch (error) {
                console.error('Error demoting member:', error);
                alert('Failed to demote member. Please try again.');
            }
        }
    };

    const fetchUsers = async (inputValue) => {
        try {
            const response = await axios.get(`/api/users?search=${inputValue}`);
            const options = response.data.map(user => ({
                value: user._id,
                label: `${user.firstName} ${user.lastName}`
            }));
            setUserOptions(options);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const handleAddMember = async () => {
        if (selectedUser) {
            try {
                await axios.put(`/api/groups/${groupId}/addMember`, { userId: selectedUser.value });
                setMembers([...members, selectedUser]);
                setSelectedUser(null);
                alert('Member added successfully!');
            } catch (error) {
                console.error('Error adding member:', error);
                alert('Failed to add member. Please try again.');
            }
        }
    };

    return (
        <div className="manage-members-container">
            <h1>Manage Members</h1>
            <ul>
                {members.map((member) => (
                    <li key={member._id} className="member-item">
                        <span>{member.firstName} {member.lastName}</span>
                        <button onClick={() => handleMenuClick(member)}>...</button>
                    </li>
                ))}
            </ul>

            {isModalOpen && selectedMember && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Manage {selectedMember.firstName}</h3>
                        <button onClick={handleRemove}>Remove from Group</button>
                        {selectedMember.isAdmin ? (
                            <button onClick={handleDemote}>Demote to Member</button>
                        ) : (
                            <button onClick={handlePromote}>Promote to Leader</button>
                        )}
                        <button onClick={() => setIsModalOpen(false)}>Cancel</button>
                    </div>
                </div>
            )}

            <div className="add-member-section">
                <h2>Add New Member</h2>
                <Select
                    options={userOptions}
                    onInputChange={fetchUsers}
                    onChange={setSelectedUser}
                    value={selectedUser}
                    placeholder="Search for users..."
                />
                <button onClick={handleAddMember} disabled={!selectedUser}>Add Member</button>
            </div>
        </div>
    );
};

export default ManageMembersPage;