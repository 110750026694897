import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { useParams, Link } from 'react-router-dom';
import '../css/StudyGuidePage.css';

const StudyGuidePage = () => {
    const { groupId, guideId } = useParams(); // Destructure groupId and guideId
    const [studyGuide, setStudyGuide] = useState(null);

    useEffect(() => {
        const fetchStudyGuide = async () => {
            try {
                const response = await axios.get(`/api/study-guides/${groupId}/${guideId}`); // Fetch study guide by groupId and guideId
                setStudyGuide(response.data);
            } catch (error) {
                console.error('Error fetching study guide:', error);
            }
        };

        fetchStudyGuide();
    }, [groupId, guideId]);

    const components = {
        a: ({ href, children }) => {
            const youtubeMatch = href.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/) ||
                                 href.match(/(?:https?:\/\/)?(?:www\.)?youtu\.be\/([a-zA-Z0-9_-]+)/);
            if (youtubeMatch) {
                const videoId = youtubeMatch[1];
                return (
                    <div className="video-container">
                        <iframe
                            width="560"
                            height="315"
                            src={`https://www.youtube.com/embed/${videoId}`}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                );
            }
            return <a href={href}>{children}</a>;
        }
    };

    if (!studyGuide) {
        return <div>Loading...</div>;
    }

    return (
        <div className="study-guide-container">
            <Link to={`/group/${groupId}/study`} className="back-button">Back to List</Link> {/* Corrected Back Link */}
            <h2>{studyGuide.title}</h2>
            <ReactMarkdown components={components}>{studyGuide.content}</ReactMarkdown> {/* Use content field */}
        </div>
    );
};

export default StudyGuidePage;