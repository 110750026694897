import React, { useState, useEffect } from 'react';
import ReactMde from 'react-mde';
import * as Showdown from 'showdown';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'react-mde/lib/styles/css/react-mde-all.css';
import '../css/EditorPage.css';
import { useGroup } from '../components/GroupContext'; // Import useGroup

const EditorPage = () => {
    const { groupId, id } = useParams(); // Include groupId
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [selectedTab, setSelectedTab] = useState('write');
    const [loading, setLoading] = useState(false);
    const { setGroupId } = useGroup(); // Destructure setGroupId from context

    const converter = new Showdown.Converter({
        tables: true,
        simplifiedAutoLink: true,
        strikethrough: true,
        tasklists: true,
    });

    useEffect(() => {
        setGroupId(groupId); // Set the groupId in context

        if (id) {
            const fetchStudyGuide = async () => {
                setLoading(true);
                try {
                    const response = await axios.get(`/api/study-guides/${groupId}/${id}`); // Include groupId
                    setTitle(response.data.title);
                    setContent(response.data.content);
                } catch (error) {
                    console.error('Error fetching study guide:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchStudyGuide();
        }
    }, [groupId, id, setGroupId]);

    const handleSave = async () => {
        if (!title || !content) {
            alert('Title and content cannot be empty.');
            return;
        }

        try {
            const payload = { title, content, groupId }; // Include groupId
            if (id) {
                await axios.put(`/api/study-guides/${groupId}/${id}`, payload); // Include groupId
                alert('Study guide updated successfully!');
            } else {
                await axios.post('/api/study-guides', payload);
                alert('Study guide created successfully!');
            }
            navigate(`/group/${groupId}/edit-study-guides`); // Navigate back after save
        } catch (error) {
            console.error('Error saving study guide:', error);
            alert('Failed to save study guide. Please try again.');
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="editor-container">
            <h1>{id ? 'Edit Study Guide' : 'Create Study Guide'}</h1>
            <input
                type="text"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
            />
            <ReactMde
                value={content}
                onChange={setContent}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={(markdown) =>
                    Promise.resolve(converter.makeHtml(markdown))
                }
            />
            <button onClick={handleSave}>{id ? 'Update' : 'Save'} Study Guide</button>
            <button onClick={() => navigate(`/group/${groupId}/edit-study-guides`)} className="back-button">
                Back to Edit Page
            </button>
        </div>
    );
};

export default EditorPage;