import React, { createContext, useContext, useState } from 'react';

const GroupContext = createContext();

export const useGroup = () => {
    return useContext(GroupContext);
};

export const GroupProvider = ({ children }) => {
    const [groupId, setGroupId] = useState(null);

    return (
        <GroupContext.Provider value={{ groupId, setGroupId }}>
            {children}
        </GroupContext.Provider>
    );
};