import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../css/AdminPage.css';
import withRole from '../components/withRole';
import { useGroup } from '../components/GroupContext'; // Import useGroup
import { useParams } from 'react-router-dom';

const AdminPage = () => {
    const params = useParams(); // Get params from URL
    const { groupId: contextGroupId } = useGroup(); // Get groupId from context

    // Use groupId from params if available, otherwise use context
    const groupId = params.groupId || contextGroupId;

    console.log('Current groupId:', groupId); // Debugging


    const clearSignUps = async () => {
        try {
            const response = await axios.delete(`/api/food-signup/${groupId}`);
            alert(response.data.message);
        } catch (error) {
            console.error('Error clearing sign-up data:', error);
            alert('Failed to clear sign-up data. Please try again.');
        }
    };

    return (
        <div className="admin-container">
            <h1>Admin Page</h1>
            <p>Welcome to the admin page.</p>
            <button onClick={clearSignUps}>Clear Sign-Up Table</button>
            <Link to={`/group/${groupId}/editor`}>
                <button>Create New Study Guide</button>
            </Link>
            <Link to={`/group/${groupId}/edit`}>
                <button>Edit Study Guides</button>
            </Link>
            <Link to={`/group/${groupId}/edit-suggestions`}>
                <button>Edit Suggestions</button>
            </Link>
            <Link to={`/group/${groupId}/edit-group-details`}>
                <button>Edit Group Details</button>
            </Link>
            <Link to="/admin/setup-group">
                <button>Setup New Group</button>
            </Link>
        </div>
    );
};

export default withRole(AdminPage, 'SmallGroupAdmin');