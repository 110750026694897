import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { useGroup } from '../components/GroupContext';
import '../css/HomePage.css';

const HomePage = () => {
    const { isAuthenticated, loginWithRedirect, user } = useAuth0();
    const [myGroups, setMyGroups] = useState([]);
    const [publicGroups, setPublicGroups] = useState([]);
    const { setGroupId } = useGroup();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [userResponse, groupsResponse] = await Promise.all([
                    fetch(`/api/users?auth0Id=${encodeURIComponent(user.sub)}`),
                    fetch('/api/groups')
                ]);

                const userData = await userResponse.json();
                const allGroups = await groupsResponse.json();

                const userGroups = allGroups.filter(group => userData.groups.includes(group._id));
                setMyGroups(userGroups);

                const publicGroups = allGroups.filter(group => group.isPublic);
                setPublicGroups(publicGroups);

                if (userGroups.length > 0) {
                    setGroupId(userGroups[0]._id);
                }

                const { sub: auth0Id, given_name: firstName, family_name: lastName, email } = user;
                await fetch('/api/users', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ auth0Id, firstName, lastName, email }),
                });

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (isAuthenticated) {
            fetchData();
        }
    }, [isAuthenticated, setGroupId, user]);

    const handleGroupSelect = (id) => {
        setGroupId(id);
    };

    return (
        <div className="home-container">
            <h1>{isAuthenticated ? 'Home' : 'Welcome to Our App!'}</h1>
            <p>{isAuthenticated ? '' : 'Discover new and exciting groups to join.'}</p>
            
            <section className="new-featured">
                <h2>New and Featured</h2>
                <p>Coming soon...</p>
            </section>

            {isAuthenticated ? (
                <section className="my-groups">
                    <h2>My Groups</h2>
                    {myGroups.length > 0 ? (
                        <ul>
                            {myGroups.map(group => (
                                <li key={group._id}>
                                    <Link to={`/group/${group._id}/about`} onClick={() => handleGroupSelect(group._id)}>
                                        {group.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>You are not assigned to any groups.</p>
                    )}
                </section>
            ) : null}

            <section className="browse-groups">
                <h2>Browse Groups</h2>
                {publicGroups.length > 0 ? (
                    <ul>
                        {publicGroups.map(group => (
                            <li key={group._id}>
                                <Link to={`/group/${group._id}/about`} onClick={() => handleGroupSelect(group._id)}>
                                    {group.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No public groups available.</p>
                )}
            </section>

            {!isAuthenticated && (
                <div className="auth-options">
                    <button onClick={() => loginWithRedirect()}>Login</button>
                    <button onClick={() => loginWithRedirect({ screen_hint: 'signup' })}>Sign Up</button>
                </div>
            )}
        </div>
    );
};

export default HomePage;