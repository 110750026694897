import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FaUser } from 'react-icons/fa';
import '../css/ProfilePage.css';
import { useGroup } from '../components/GroupContext'; // Import useGroup
import { useParams } from 'react-router-dom';


const ProfilePage = ({ darkMode, toggleTheme }) => {
    const { loginWithRedirect, logout, isAuthenticated, user, error } = useAuth0();
    const params = useParams(); // Get params from URL
    const { groupId: contextGroupId } = useGroup(); // Get groupId from context

    // Use groupId from params if available, otherwise use context
    const groupId = params.groupId || contextGroupId;

    console.log('Current groupId:', groupId); // Debugging



    console.log('isAuthenticated:', isAuthenticated);
    console.log('User:', user);
    console.log('Current groupId:', groupId); // Debugging
    if (error) console.error('Auth0 Error:', error);

    const isAdmin = isAuthenticated && user && user['https://groupdev.petieclark.com/roles']?.includes('SmallGroupAdmin');

    return (
        <div className="profile-page">
            <div className="profile-header">
                {isAuthenticated ? (
                    <img src={user.picture} alt="Profile" />
                ) : (
                    <FaUser size={100} color="var(--text-color)" />
                )}
                <h2>{isAuthenticated ? `Welcome, ${user.name}` : 'Please Log In'}</h2>
            </div>
            <div className="profile-menu">
                {isAuthenticated ? (
                    <button onClick={() => logout({ returnTo: window.location.origin })}>
                        Log Out
                    </button>
                ) : (
                    <button onClick={() => loginWithRedirect()}>Log In</button>
                )}
                <button onClick={toggleTheme}>
                    {darkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
                </button>
                {isAdmin &&  (
                    <button onClick={() => window.location.href = `/group/${groupId}/admin`}>
                        Admin Page
                    </button>
                )}
            </div>
        </div>
    );
};

export default ProfilePage;