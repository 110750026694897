import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useGroup } from '../components/GroupContext';
import axios from 'axios';
import '../css/GroupDetails.css';
import '../css/EditorPage.css';

const EditGroupDetailsPage = ({ setHeaderText = () => {}, setMenuVisibility = () => {}, darkMode }) => {
    const params = useParams();
    const navigate = useNavigate();
    const { groupId: contextGroupId } = useGroup();

    const groupId = params.groupId || contextGroupId;

    console.log('Current groupId:', groupId);
    const [groupName, setGroupName] = useState('');
    const [groupDescription, setGroupDescription] = useState('');
    const [menuVisibility, setLocalMenuVisibility] = useState({
        signup: true,
        suggestions: true,
        study: true,
    });
    
    // Ensure menuVisibility is an object with boolean values, not an array

    // Define state for edit modal
    const [editField, setEditField] = useState('');
    const [editContent, setEditContent] = useState('');
    const [editModalOpen, setEditModalOpen] = useState(false);

    useEffect(() => {
        const fetchGroupDetails = async () => {
            try {
                const response = await axios.get(`/api/groups/${groupId}`);
                console.log('API Response:', response.data); // Log the API response
                const { name, description, menuVisibility } = response.data;
                setGroupName(name || 'Group Name');
                setGroupDescription(description || 'Group Description');
                setLocalMenuVisibility(menuVisibility || {
                    signup: true,
                    suggestions: true,
                    study: true,
                });
            } catch (error) {
                console.error('Error fetching group details:', error);
            }
        };
    
        if (groupId) {
            fetchGroupDetails();
        }
    }, [groupId]);

    const handleSave = async () => {
        try {
            await axios.put(`/api/groups/${groupId}`, {
                name: groupName,
                description: groupDescription,
                menuVisibility,
            });
            alert('Group details updated successfully!');
        } catch (error) {
            console.error('Error saving group details:', error);
            alert('Failed to save group details. Please try again.');
        }
    };

    const openEditModal = (field, content) => {
        setEditField(field);
        setEditContent(content);
        setEditModalOpen(true);
    };

    const closeEditModal = () => {
        setEditModalOpen(false);
    };

    const saveEdit = () => {
        if (editField === 'name') {
            setGroupName(editContent);
        } else if (editField === 'description') {
            setGroupDescription(editContent);
        }
        closeEditModal();
    };

    return (
        <div className="editor-container">
            <h1>Edit Group Details</h1>
            <div>
                <button onClick={() => openEditModal('name', groupName)}>Edit Group Name</button>
            </div>
            <div>
                <button onClick={() => openEditModal('description', groupDescription)}>Edit Group Description</button>
            </div>
            <div>
                <label>Menu Visibility:</label>
                <div className="toggle-container">
                    Food Sign-Up
                    <label className="toggle-switch">
                        <input
                            type="checkbox"
                            checked={menuVisibility.signup}
                            onChange={() =>
                                setLocalMenuVisibility((prev) => ({
                                    ...prev,
                                    signup: !prev.signup,
                                }))
                            }
                        />
                        <span className="slider"></span>
                    </label>
                </div>
                <div className="toggle-container">
                    Food Suggestions for This Week
                    <label className="toggle-switch">
                        <input
                            type="checkbox"
                            checked={menuVisibility.suggestions}
                            onChange={() =>
                                setLocalMenuVisibility((prev) => ({
                                    ...prev,
                                    suggestions: !prev.suggestions,
                                }))
                            }
                        />
                        <span className="slider"></span>
                    </label>
                </div>
                <div className="toggle-container">
                    Study Guides
                    <label className="toggle-switch">
                        <input
                            type="checkbox"
                            checked={menuVisibility.study}
                            onChange={() =>
                                setLocalMenuVisibility((prev) => ({
                                    ...prev,
                                    study: !prev.study,
                                }))
                            }
                        />
                        <span className="slider"></span>
                    </label>
                </div>
            </div>
            <button onClick={() => navigate(`/group/${groupId}/manage-members`)}>Manage Members</button>
            <button onClick={handleSave}>Save Changes</button>
            <Link to={`/group/${groupId}/admin`} className="back-button">Back to Admin</Link>
        </div>
    );
};

export default EditGroupDetailsPage;