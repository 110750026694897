   // src/components/withRole.js
   import React from 'react';
   import { useAuth0 } from '@auth0/auth0-react';
   import { Navigate } from 'react-router-dom';

   const withRole = (Component, requiredRole) => {
     return (props) => {
       const { isAuthenticated, user, isLoading } = useAuth0();

       if (isLoading) {
         return <div>Loading...</div>;
       }

       if (!isAuthenticated || !user || !user['https://groupdev.petieclark.com/roles']?.includes(requiredRole)) {
         return <Navigate to="/" />;
       }

       return <Component {...props} />;
     };
   };

   export default withRole;